
import { defineComponent, onMounted, ref, reactive } from "vue";
import { getOrganization } from "@/core/services/JwtService";
import { getPanel } from "@/api/encounter.api";
import Multiselect from "@vueform/multiselect";
import { useRoute, useRouter } from "vue-router";
import { searchProviders } from "@/api/provider.api";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import PaginationUi from "@/components/ABilling/Pagination.vue";
import useFilters from "@/modules/common/useFilters";
import debounce from 'lodash.debounce'

export interface Pagination {
  currentPage: number;
  totalPages: number;
  totalCount: number;
  pageSize: number;
}
export interface Data {
  emptyListError?: string;
  items: any[];
  keyword: string;
  pagination: Pagination;
  orderBy: string;
  isDecr: boolean;
  panel: any;
  IsAdvancedOrderBy: boolean;
  dict: {
    boolList: { code?: boolean | null; name: string }[];
    credentialsList : { name: string }[];
  };
  filter: {
    attending: boolean | null;
    billing: boolean | null;
    rendering: boolean | null;
    primaryFacility: string | null;
    credentials: string | null;
  };
  showInactive: boolean;
}
export default defineComponent({
  name: "Providers",
  components: { PaginationUi, Multiselect },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const {
      orderList,
      putFiltersToUrl,
      getFiltersFromUrl,
      getFilter,
    } = useFilters();

    orderList.value = [
      {
        name: "Name",
        key: "lastName",
        isFilter: false,
        keyword: null,
      },
      {
        name: "Credentials",
        key: "credentials",
        isFilter: false,
        keyword: null,
      },
      {
        name: "NPI",
        key: "npi",
        isFilter: false,
        keyword: null,
      },
      {
        name: "Primary Practice Facility",
        key: "primaryFacility",
        isFilter: false,
        keyword: null,
      },
      {
        name: "Attending",
        key: "attending",
        isFilter: false,
        keyword: null,
        formType: "check",
      },
      {
        name: "Billing",
        key: "billing",
        isFilter: false,
        keyword: null,
        formType: "check",
      },
      {
        name: "Rendering",
        key: "rendering",
        isFilter: false,
        keyword: null,
        formType: "check",
      },
    ];

    let organizationId = ref<string | null>("");

    let data = reactive<Data>({
      items: [],
      keyword: "",
      orderBy: "lastName",
      IsAdvancedOrderBy: false,
      isDecr: false,
      showInactive: false,
      pagination: {
        currentPage: 1,
        totalPages: 0,
        totalCount: 0,
        pageSize: 50,
      },
      filter: {
        attending: null,
        billing: null,
        rendering: null,
        primaryFacility: null,
        credentials: null,
      },
      panel: {},
      dict: {
        boolList: [
          { code: true, name: "Yes" },
          { code: false, name: "No" },
          { code: null, name: "All" },
        ],
        credentialsList: [],
      },
    });

    async function cleanFilter() {
      data.keyword = "";
      data.orderBy = "lastName";
      data.IsAdvancedOrderBy = false;
      data.isDecr = false;
      data.filter.attending ="All" as any;
      data.filter.billing ="All" as any;
      data.filter.rendering ="All" as any;
      data.filter.primaryFacility =  "All";
      data.filter.credentials ="All";
      data.showInactive = false;
      orderList.value.forEach((item) => {
        item.keyword = null;
      });
      await getAll();
    }

    onMounted(async () => {
      data.panel = await getPanel();
      data.panel.facilities.push({ id: "", name: "All" });
      data.filter.attending = "All" as any;
      data.filter.billing = "All" as any;
      data.filter.rendering = "All" as any;
      data.filter.primaryFacility = "All";
      data.filter.credentials = "All";
      data.panel.providers.forEach((item: any) => {
        if(!data.dict.credentialsList.includes(item.credentials) && item.credentials)data.dict.credentialsList.push({ name: item.credentials });
      });
      data.dict.credentialsList.unshift({ name: "All" });
      getFiltersFromUrl();
      organizationId.value = getOrganization();
      setCurrentPageTitle("Providers");
      await getAll();
    });

    async function selectFilter(header) {
      if (data.orderBy == header.key) {
        data.isDecr = !data.isDecr;
      } else {
        data.isDecr = false;
      }
      data.orderBy = header.key;
      data.IsAdvancedOrderBy = header.IsAdvancedOrderBy;

      await search();
    }

    function getSortInfo(key) {
      if (data.orderBy == key) return true;
      return false;
    }

    async function debounceSearch() {
      await debounce(search, useFilters().debounceMs)();
    }

    async function search() {
      data.pagination.currentPage = 1;
      await getAll();
    }

    function getTrueOrFalse(value) {
      if (value=="Yes") {
        return true;
      }
      if (value == "No") {
        return false;
      }
      return null;
    }

    function getCredentialsFilter(value) {
      if (value == "All") {
        return null;
      }
      return value;
    }

    async function getAll() {
      putFiltersToUrl();
      let order = data.orderBy;
      if (data.isDecr) {
        order = order + " Desc";
      }

      let orderBy: string[] | null = null;
      let advancedOrderBy: string | null = null;

      if (order && !data.IsAdvancedOrderBy) {
        orderBy = [order];
      } else {
        advancedOrderBy = order;
      }
      let request = {
        pageNumber: data.pagination.currentPage,
        pageSize: data.pagination.pageSize,
        orderBy: orderBy,
        advancedOrderBy: advancedOrderBy,
        advancedSearch: {
          fields: orderList.value.map((item) => {
            return item.key;
          })
        },
        Credentials: getCredentialsFilter(data.filter.credentials),
        PrimaryFacility: getPrimaryFacilityFilter(data.filter.primaryFacility),
        Attending: getTrueOrFalse(data.filter.attending),
        Billing: getTrueOrFalse(data.filter.billing),
        Rendering: getTrueOrFalse(data.filter.rendering),
        ShowInactive: data.showInactive,
        GlobalSearch: data.keyword
      };

      const res = await searchProviders(request);
      data.items = res.data;
      data.pagination.currentPage = res.currentPage;
      data.pagination.totalPages = res.totalPages;
      data.pagination.totalCount = res.totalCount;
      data.pagination.pageSize = res.pageSize;

      data.emptyListError = undefined;
      if (data.items.length == 0) {
        const currentFilters = orderList.value.filter((f) => {
          return (
            f.keyword != undefined && f.keyword !== "" && f.keyword != null
          );
        });
        if (currentFilters.length > 0) {
          data.emptyListError =
            "**No results match your filter criteria. Please clear all filters to view list";
        } else {
          data.emptyListError = "List is Empty";
        }
      }
    }

    function selectItem(item) {
      const path = encodeURIComponent(route.fullPath);
      router.push({
        path: "/settings/providerPage/" + item.id + "/",
        query: { breadcrumb: path }
      });
    }

    function getPrimaryFacilityFilter(value) {
      if (value == "All") {
        return null;
      }
      return value;
    }

    async function clearSearch(header) {
      header.keyword = null;
      await search();
    }

    async function pageChanged(page) {
      data.pagination.currentPage = page;
      await getAll();
    }

    async function pageSizeChanged(pageSize) {
      data.pagination.pageSize = pageSize;
      data.pagination.currentPage = 1;
      await getAll();
    }

    function getFacilityDisplay(facility) {
      if (facility == null || !facility) return "None";
      else return facility;
    }

    function getCredentialsDisplay(credentials) {
      if (credentials == null || !credentials) return "None";
      else return credentials;
    }

    return {
      organizationId,
      clearSearch,
      getFacilityDisplay,
      getCredentialsDisplay,
      data,
      getAll,
      selectItem,
      orderList,
      search,
      debounceSearch,
      getSortInfo,
      selectFilter,
      cleanFilter,
      pageChanged,
      pageSizeChanged,
    };
  },
});
